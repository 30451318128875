import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Row
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { getFactories } from "../../../store/slices/factory/factoryActions";
import { editMiniBusinesses } from "../../../store/slices/mini-business/miniBusinessActions";
import { useTranslation } from "react-i18next";

const EditMiniBusiness = () => {
    const { t } = useTranslation()
    const factories = useSelector((state) => state?.factory?.factories) || [];
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { data } = location.state;
    const id = useParams().id;
    const englishName = register("englishName", {
        required: "Please enter Business unit name in English",
        minLength: {
            value: 4,
            message: "Business unit name must be at least 4 characters",
        },
    });
    const arabicName = register("arabicName", {
        required: "Please enter Business unit name in Arabic",
        minLength: {
            value: 4,
            message: "Business unit name must be at least 4 characters",
        },
    });
    const submitEditForm = async (data) => {
        const miniBusiness = {
            ...data,
            name: {
                en: data.englishName,
                ar: data.arabicName
            },
            factory: data.factory,
            _id: id
        };
        let result = await confirm({
            title: <>Warning</>,
            message: t("Are you sure you want to edit this?"),
            confirmText: "Confirm",
            confirmColor: "primary",
            cancelColor: "link text-danger",
        });
        if (result) {
            dispatch(editMiniBusinesses(miniBusiness));
            history.push("/admin/miniBusiness");
        };

    };
    useEffect(() => {
        if (!factories.legth)
            dispatch(getFactories());
    }, []);
    return (
        <>
            <SimpleHeader name="Edit Business unit" parentName="Management" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">{t("masterData.Edit")}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form" onSubmit={handleSubmit(submitEditForm)}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    {t("masterData.English Name")}
                                                </label>
                                                <Input
                                                    id="validationCustom01"
                                                    placeholder="English Name..."
                                                    type="text"
                                                    name="englishName"
                                                    onChange={englishName.onChange}
                                                    defaultValue={data?.name?.en}
                                                    onBlur={englishName.onBlur}
                                                    innerRef={englishName.ref}
                                                />
                                                <small className="text-danger">
                                                    {errors.englishName && errors.englishName.message}
                                                </small>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    {t("masterData.Arabic Name")}
                                                </label>
                                                <Input
                                                    id="validationCustom01"
                                                    placeholder="Arabic Name..."
                                                    type="text"
                                                    name="arabicName"
                                                    onChange={arabicName.onChange}
                                                    defaultValue={data?.name?.ar}
                                                    onBlur={arabicName.onBlur}
                                                    innerRef={arabicName.ref}
                                                />
                                                <small className="text-danger">
                                                    {errors.arabicName && errors.arabicName.message}
                                                </small>
                                            </Col>
                                        </div>
                                        <Button color="primary" type="submit">
                                            {t("submit")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default EditMiniBusiness;
